import styled, {DefaultTheme, css} from 'styled-components'

export const StyledDropdownRoot = styled.div`
    ${() => css`
        position: relative;
    `}
`

export const StyledMenuWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: ${spacing * 3}px;
        padding: ${spacing * 3}px 0px;
        & > div + div {
            border-top: 1px solid ${palette.gray.disabled};
            width: 100%;
            padding-top: ${spacing * 3}px;
        }
    `}
`

export const StyledMenuItem = styled.div`
    ${({theme: {palette, typography, spacing}, disabled}: {disabled?: boolean; theme: DefaultTheme}) => css`
        ${typography.textS}
        display: grid;
        grid-template-columns: auto 1fr;
        gap: ${spacing * 2}px;
        cursor: pointer;
        color: ${disabled ? palette.gray.disabled : palette.gray.main};
        font-weight: 500;
        transition: color 0.3s ease;
        padding: 0px ${spacing * 3}px;
        align-items: center;
        text-wrap: nowrap;
        white-space: nowrap;
        text-align: left;
        &:hover {
            color: ${disabled ? palette.gray.disabled : palette.blue.main};
        }
        & div {
            width: 18px;
            display: flex;
            justify-content: center;
        }
    `}
`
