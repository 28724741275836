import styled, {css} from 'styled-components'

export const StyledInfoRoot = styled.div`
    ${({theme: {mediaQueries}}) => css`
        align-self: center;
        max-width: 100%;
        ${mediaQueries.m} {
            max-width: 250px;
            align-self: self-start;
        }
    `}
`

export const StyledUserInfoWrapper = styled.div`
    ${({theme: {spacing, typography}}) => css`
        display: grid;
        grid-template-columns: 0fr auto;
        align-items: center;
        gap: ${spacing * 4}px;

        & span {
            ${typography.textXXXL}
        }
    `}
`

export const StyledUserName = styled.div`
    ${({theme: {spacing, palette, typography, truncateText}}) => css`
        ${typography.subtitle};
        color: ${palette.gray.main};
        ${truncateText}
        & p {
            ${typography.textL};
            font-weight: 400;
            color: ${palette.gray.dark};
            margin-top: ${spacing}px;
        }
        & small {
            ${typography.textS}
            display: block;
            color: ${palette.neutral[500]};
            ${truncateText}
        }
    `}
`

export const StyledUserRole = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        ${typography.textXL}
        font-weight: 700;
        color: ${palette.gray.main};
        margin: ${spacing * 6}px 0px ${spacing * 3}px;

        & span {
            text-transform: capitalize;
            font-weight: 400;
            color: ${palette.gray.dark};
        }
    `}
`

export const StyledDescription = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        background-color: ${palette.gray.lightBG};
        padding: ${spacing * 2}px;
        border-radius: ${spacing}px;

        & span {
            ${typography.textM};
            color: ${palette.gray.main};
        }
    `}
`
