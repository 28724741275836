import styled, {css} from 'styled-components'

export const StyledWarningIconWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        margin-bottom: ${spacing}px;
    `}
`

export const StyledDisclaimer = styled.span`
    ${() => css`
        width: 500px;
        display: block;
    `}
`

export const StyledHeaderWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing * 1.5}px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: ${spacing * 4}px;
    `}
`

export const StyledInputWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        gap: ${spacing * 1.5}px;
        border: 1px solid ${palette.neutral['300']};
        border-radius: 15px;
        padding: ${spacing * 2}px ${spacing * 4}px;
        justify-content: space-between;
        & > input {
            width: 85%;
        }
    `}
`

export const StyledContentWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        height: 380px;
        overflow-y: auto;
        padding: ${spacing * 1}px;
    `}
`

export const StyledListWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: ${spacing * 4}px;
        margin-bottom: ${spacing * 4}px;
    `}
`

export const StyledListItemWrapper = styled.div`
    ${({theme: {spacing, shadows}}) => css`
        box-shadow: ${shadows.sm};
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        border-radius: 10px;
        overflow: hidden;
    `}
`

export const StyledListItemImage = styled.img`
    ${() => css`
        height: 120px;
        object-fit: cover;
    `}
`

export const StyledListItemTitle = styled.span`
    ${({theme: {spacing, typography, palette}}) => css`
        ${typography.textL}
        font-weight: 500;
        color: ${palette.primary[900]};
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        padding: ${spacing * 2}px ${spacing * 4}px;
        height: 50px;
    `}
`

export const StyledListItemUrl = styled.a`
    ${({theme: {spacing, palette}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        word-break: break-all;
        padding: 0 ${spacing * 4}px;
        color: ${palette.neutral[900]};
        text-decoration: underline;
        cursor: pointer;
    `}
`

export const StyledListValueLabel = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        gap: ${spacing}px;
        align-items: center;
        padding: ${spacing}px ${spacing * 4}px;
    `}
`

export const StyledListLabel = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral[900]};
    `}
`

export const StyledListValue = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.neutral[900]};
    `}
`

export const StyledListAction = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        gap: ${spacing * 1.5}px;
        padding: ${spacing * 4}px;
    `}
`
