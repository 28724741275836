import {
    StyledCoverVideo,
    StyledDeleteButton,
    StyledDeleteButtonWrapper,
    StyledDescription,
    StyledEuropeanaLogo,
    StyledFooterWrapper,
    StyledLink,
    StyledNameWrapper,
    StyledOverlay,
    StyledRoot,
    StyledStatusLabelWrapper,
    StyledTextItem,
    StyledTooltip,
    StyledTooltipWrapper,
    StyledVideoName
} from './style'
import {Trans, useTranslation} from 'react-i18next'
import {getLocalDate} from '@/utilities/helpers'
import Button from '@/components/ui/Button'
import Europeana_logo from '@assets/images/Europeana_logo.png'
import {generatePath, useNavigate, useParams} from 'react-router-dom'
import {ROUTE_VIDEO_INGESTION} from '@/utilities/constants'
import Video from '@/components/ui/Video'
import Tag from '@/components/ui/Tag'
import {VideoType} from '../../types'
import {DeleteIcon, DotsVerticalIcon} from '@/assets/icons/icons'
import {useState} from 'react'
import {useTheme} from 'styled-components'
import VideoDeletionModal from '../video-deletion-modal/VideoDeletionModal'

const VideoCard = ({video}: {video: VideoType}) => {
    const {t} = useTranslation()
    const {id, status, name, sourceLanguage, videoUrl, jobs, createdAt, isEuropeana, originalUrl} = video
    const navigate = useNavigate()
    const {workspaceId} = useParams()
    const theme = useTheme()
    const localDate = createdAt ? getLocalDate(createdAt, 'DD/MM/YYYY') : '--'
    const [isVideoDeletionModalOpen, setIsVideoDeletionModalOpen] = useState(false)
    const [isDeleteActionTooltipOpen, setDeleteActionTooltipOpen] = useState(false)

    const onClickDetails = (item: VideoType) => {
        navigate(generatePath(ROUTE_VIDEO_INGESTION, {workspaceId: `${workspaceId}`, videoId: `${item.id}`}))
    }
    const targetLanguagesList = jobs.map(job => job.targetLanguage.name).join(', ')

    return (
        <>
            <StyledRoot>
                <div>
                    <StyledCoverVideo>
                        <Video source={videoUrl} height={140} poster={video?.thumbnailUrl} />
                    </StyledCoverVideo>
                    <StyledDescription>
                        <StyledStatusLabelWrapper>
                            <Tag type={status}>{t(`job_status:${status}`)}</Tag>
                        </StyledStatusLabelWrapper>
                        <StyledNameWrapper>
                            <StyledVideoName>{name}</StyledVideoName>
                            <StyledDeleteButtonWrapper>
                                <StyledDeleteButton>
                                    <DotsVerticalIcon
                                        fill={theme.palette.neutral['900']}
                                        height={20}
                                        onClick={() => {
                                            setDeleteActionTooltipOpen(true)
                                        }}
                                    />
                                </StyledDeleteButton>

                                {isDeleteActionTooltipOpen && (
                                    <>
                                        <StyledOverlay onClick={() => setDeleteActionTooltipOpen(false)} />
                                        <StyledTooltipWrapper>
                                            <StyledTooltip
                                                onClick={() => {
                                                    setIsVideoDeletionModalOpen(true)
                                                    setDeleteActionTooltipOpen(false)
                                                }}
                                            >
                                                <DeleteIcon fill={theme.palette.neutral['500']} />
                                                {t('common:delete')}
                                            </StyledTooltip>
                                        </StyledTooltipWrapper>
                                    </>
                                )}
                            </StyledDeleteButtonWrapper>
                        </StyledNameWrapper>

                        <StyledLink>{originalUrl}</StyledLink>
                        <StyledTextItem>
                            <Trans
                                t={t}
                                i18nKey="workspace:video_card:original_language"
                                values={{LANGUAGE_NAME: sourceLanguage?.name}}
                                components={{
                                    span: <span />
                                }}
                            />
                        </StyledTextItem>
                        <StyledTextItem>
                            <Trans
                                t={t}
                                i18nKey="workspace:video_card:target_language"
                                values={{LANGUAGE_LIST: targetLanguagesList}}
                                components={{
                                    span: <span />
                                }}
                            />
                        </StyledTextItem>
                        <StyledTextItem>
                            <Trans
                                t={t}
                                i18nKey="workspace:video_card:date"
                                values={{UPLOAD_DATE: localDate}}
                                components={{
                                    span: <span />
                                }}
                            />
                        </StyledTextItem>
                    </StyledDescription>
                </div>
                <StyledFooterWrapper>
                    <div>
                        {!!isEuropeana && (
                            <StyledEuropeanaLogo>
                                <img src={Europeana_logo} alt={'Europeana logo'} />
                                <span> {t('workspace:video_card:on_europeana')}</span>
                            </StyledEuropeanaLogo>
                        )}
                    </div>

                    <Button variant={'primary'} size={'md'} onClick={() => onClickDetails(video)}>
                        {t('common:see_details')}
                    </Button>
                </StyledFooterWrapper>
            </StyledRoot>
            {isVideoDeletionModalOpen && (
                <VideoDeletionModal
                    onClose={() => setIsVideoDeletionModalOpen(false)}
                    workspaceId={`${workspaceId}`}
                    videoId={`${id}`}
                    videoName={name}
                />
            )}
        </>
    )
}

export default VideoCard

VideoCard.displayName = 'VideoCard'
