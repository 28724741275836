import React from 'react'
import {StyledDescription, StyledInfoRoot, StyledUserInfoWrapper, StyledUserName, StyledUserRole} from './style'
import Avatar from '@/components/ui/Avatar'
import useAuthStore from '@/store/authStore'
import {Trans, useTranslation} from 'react-i18next'
import {getLocalDate} from '@/utilities/helpers'

const ProfileCard: React.FC = () => {
    const {t} = useTranslation()
    const user = useAuthStore(store => store.user)
    const localDate = user?.createdAt ? getLocalDate(user?.createdAt, 'MMM D, YYYY') : '--'

    return (
        <StyledInfoRoot>
            <StyledUserInfoWrapper>
                <Avatar name={user?.name ?? ''} size={80} />
                <StyledUserName>
                    {user?.name}
                    <small>{user?.username}</small>
                    <p>{t('my_profile:info:start_date', {DATE: localDate})}</p>
                </StyledUserName>
            </StyledUserInfoWrapper>
            <StyledUserRole>
                <Trans
                    t={t}
                    i18nKey="my_profile:info:user_role"
                    values={{
                        USER_ROLE: user?.type
                    }}
                    components={{
                        span: <span />
                    }}
                />
            </StyledUserRole>
            <StyledDescription>
                <span>{t('my_profile:info:description')}</span>
            </StyledDescription>
        </StyledInfoRoot>
    )
}

export default ProfileCard

ProfileCard.displayName = 'ProfileCard'
